import React from 'react';
import styled from 'styled-components';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import {useOutlet} from 'reconnect.js';
import {formatDateStr} from '../../Utils/TimeUtil';

function getPaymentTime(record) {
  if (record) {
    if (record.payment_transaction_detail?.PayTime) {
      return formatDateStr(
        new Date(record.payment_transaction_detail?.PayTime),
      );
    } else if (record.offline_date) {
      return record.offline_date;
    } else {
      return formatDateStr(new Date(record.created));
    }
  }

  return '';
}

function OrderReceiptPreview(props) {
  const [config] = useOutlet('config');
  const {record} = props;

  const isNotFeeOrder = record
    ? record.items[0].product.name.indexOf('會費') === -1
    : false;

  return (
    <Wrapper>
      {record && (
        <div className="page">
          <h1>{`社團法人台北律師公會\n收據`}</h1>
          <div className="content">
            <div className="row">
              <div className="container">
                <label>收據編號：</label>
                <span>{record.order_id}</span>
              </div>
              <div className="container">
                <label>收據日期：</label>
                <span>{getPaymentTime(record)}</span>
              </div>
            </div>
            <div className="row">
              <div className="cell small">
                <label>收據抬頭</label>
              </div>
              <div className="cell">
                <label>
                  {record.receipt_email_data?.receipt_title || '---'}
                </label>
              </div>
              <div className="cell small">
                <label>統一編號</label>
              </div>
              <div className="cell">
                <label>{record.receipt_email_data?.gui_number || '---'}</label>
              </div>
            </div>
            <div className="row">
              <div className="cell small">
                <label>金額大寫</label>
              </div>
              <div className="cell">
                <label>{number2text(record.total)} 元整</label>
              </div>
              <div className="cell small">
                <label>金額小寫</label>
              </div>
              <div className="cell">
                <label>{record.total} 元整</label>
              </div>
            </div>
            <div className="row">
              <div className="cell small">
                <label>內容</label>
              </div>
              <div className="cell">
                {isNotFeeOrder
                  ? `${record.buyer_name} ${record.items[0].product.name}`
                  : `${record.buyer_name} 律師 ${
                      record.annual_year ? `${record.annual_year}年` : ``
                    }${record.items[0].product.name}`}
              </div>
            </div>
            <div className="row">
              <div className="cell small">
                <label>付款方式</label>
              </div>
              <div className="cell">
                <label>
                  {record.payment_subtype !== 'offline' &&
                    Cart.PAYMENT_SUBTYPE_DISPLAY[record.payment_subtype]?.label}
                  {record.payment_subtype === 'offline' &&
                    record.payment_offline_method &&
                    ` ${record.payment_offline_method}`}
                </label>
              </div>
            </div>
            <div className="row has-border-bottom">
              <div className="cell small">
                <label>聯絡電話</label>
              </div>
              <div className="cell">
                <label>{record.receiver_phone}</label>
              </div>
            </div>
            <div className="row">
              <div className="container">
                <div>社團法人台北律師公會</div>
                <div>統一編號：78379129</div>
                <div>地址：100 台北市中正區羅斯福路一段7號9樓</div>
                <div>電話：(02) 2351-5071</div>
                <div>傳真：(02) 2391-3714</div>
              </div>
              <div className="container">
                <div>理事長：{record.chairman}</div>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="row">
              {isNotFeeOrder && (
                <div className="container">
                  <div className="stamp-duty">
                    <div>社團法人台北律師公會</div>
                    <div>本銀錢收據</div>
                    <div>印花稅總繳</div>
                    <div>台北市 負責總繳人 {record.chairman}</div>
                  </div>
                </div>
              )}

              {config?.stamp_receipt && (
                <div className="container">
                  <div className="stamp-receipt">
                    <img
                      alt="stamp_receipt"
                      src={config.stamp_receipt}
                      style={{width: 150}}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .page {
    & > h1 {
      margin-bottom: 5px;
      text-align: center;
      white-space: pre-wrap;
      position: relative;
      z-inde: 1;
    }

    & > div.content {
      position: relative;
      z-inde: 1;

      & > div.row {
        display: flex;

        & > div.container {
          flex: 1;
          padding-top: 10px;
          padding-bottom: 5px;
          font-size: 0.95rem;

          & > .stamp-duty {
            border: 1px solid #000000;
            width: 250px;
            padding: 10px;
          }
        }

        & > div.cell {
          flex: 1;
          padding: 5px 10px;
          border: 1px solid #000000;
          border-right: none;
          border-bottom: none;
          font-size: 1rem;

          &.small {
            flex: 0.5;
          }

          :last-child {
            border-right: 1px solid #000000;
          }
        }

        &.has-border-bottom {
          border-bottom: 1px solid #000000;
        }
      }
    }

    & > .stamp-receipt {
    }
  }
`;

function number2text(num) {
  const arr = ['元', '拾', '佰', '仟', '萬', '拾', '佰', '仟', '億'];
  const numArr = ['零', '壹', '貳', '参', '肆', '伍', '陸', '柒', '捌', '玖'];
  let idx = 0;
  return [...num.toString()]
    .reduceRight((pre, val) => numArr[val] + arr[idx++] + pre, '')
    .replace(/(零\S){2,}/, '零');
}

export default OrderReceiptPreview;
